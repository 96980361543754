import React, { useState, useEffect } from 'react';

import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { useId, useBoolean } from '@uifabric/react-hooks';
import { Label } from 'office-ui-fabric-react/lib/Label';

import { DetailsList, DetailsListLayoutMode, Selection, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';



import axios, { AxiosResponse } from 'axios';
import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';

import gamePieceModel from '../../models/gamePieceModel';
import returnPiecesModel from '../../models/ReturnPiecesModel';

interface UserHistoryProps {
    gameCode: string,
    userId: string
}

export const UserHistory: React.FC<UserHistoryProps> = (props: UserHistoryProps) => {

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [error, setError] = useState({ visible: false, message: '' });
    const [history, setHistory] = useState<gamePieceModel[]>();
    const [itemsToReturn, setItemsToReturn] = useState<gamePieceModel[]>([]);
    const [returningPieces, setReturningPieces] = useState<boolean>(false);
    const [returningPiecesError, setReturningPiecesError] = useState<boolean>(false);
    const [returnDialogHidden, setReturnDialogHIdden] = useState<boolean>(true);

    const stackTokens: IStackTokens = {
        childrenGap: 20
    };

    const smallStackTokens: IStackTokens = {
        childrenGap: 10
    };

    useEffect(() => {
        requestHistory();
    }, [props.gameCode, props.userId]);

    function requestHistory() {
        setLoading(true);
        setLoadingMessage("Requesting history...");

        if (props.gameCode === '' || props.userId === '') {
            return;
        }

        axios.get(`_api/Game/GetHistory?userId=${props.userId}&gameCode=${props.gameCode}`)
            .then((response: AxiosResponse<gamePieceModel[]>) => {
                setLoading(false);
                setLoadingMessage("");
                setHistory(response.data);
            })
            .catch((err) => {
                setLoading(false);
                setLoadingMessage("");
                setError({ visible: true, message: "Error requesting history " + err.message })
            });
    }

    function confirmReturnPieces() {
        if (props.gameCode === '' || props.userId === '') {
            setReturningPiecesError(true);
            return;
        }

        setReturningPieces(true);

        var model: returnPiecesModel = {
            gameCode: props.gameCode,
            userId: props.userId,
            pieces: itemsToReturn
        };


        axios.put('_api/Game/ReturnPieces', model)
            .then(() => {
                setReturningPieces(false);
                setReturnDialogHIdden(true);
                requestHistory();

            })
            .catch((err) => {
                setReturningPieces(false);
                setReturningPiecesError(true);
            })
    }

    const _selection: Selection = new Selection({
        onSelectionChanged: () => {
            setItemsToReturn(_selection.getSelection() as gamePieceModel[]);

        }
    });

    const returnDialogContentProps = {
        type: DialogType.normal,
        title: 'Confirm',
        closeButtonAriaLabel: 'Close',
        subText: 'Are you sure you want to return these pieces? This action cannot be undone.',
    };

    const _columns: IColumn[] = [
        { key: 'gamePiece', name: 'Game Piece', fieldName: 'name', minWidth: 100, isResizable: false }
    ];

    return (
        <>
            <Stack tokens={stackTokens}>
                {error.visible &&
                    <Text variant={'medium'} block>{error.message}</Text>
                }
                {!error.visible && loading &&
                    <Spinner label={loadingMessage}></Spinner>
                }
                {!error.visible && !loading && history !== undefined && history.length > 0 &&
                    <>
                    <Text variant={'medium'} block>View a history of your pieces and select some to put back</Text>
                    <PrimaryButton text="Give Back" disabled={itemsToReturn === undefined || itemsToReturn.length === 0} onClick={() => { setReturnDialogHIdden(false); }} />
                        <MarqueeSelection selection={_selection}>
                            <DetailsList
                                items={history}
                                columns={_columns}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                selection={_selection}
                                selectionPreservedOnEmptyClick={true}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                checkButtonAriaLabel="Row checkbox"
                            />
                        </MarqueeSelection>
                    </>
                }
                {!error.visible && !loading && (history === undefined || history.length === 0) &&
                    <Text variant={'medium'} block>You have not taken any pieces</Text>
                }
            </Stack>

            <Dialog
                hidden={returnDialogHidden}
                onDismiss={() => { setReturnDialogHIdden(true); }}
                dialogContentProps={returnDialogContentProps}
            >
                {returningPiecesError &&
                    <Text variant={'medium'} block>Oops something went wrong. Please try again</Text>
                }
                {returningPieces && !returningPiecesError &&
                    <div>
                        <Spinner label="Returning pieces. Please wait..." />
                    </div>
                }
                <DialogFooter>
                    <PrimaryButton onClick={confirmReturnPieces} text="Confirm" disabled={returningPieces || returningPiecesError} />
                    <DefaultButton onClick={() => { setReturnDialogHIdden(true); setReturningPiecesError(false); }} text="Cancel" disabled={returningPieces} />
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default UserHistory;