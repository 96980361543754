import React, { useState, useEffect } from 'react';

import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { Panel } from 'office-ui-fabric-react/lib/Panel';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { useBoolean } from '@uifabric/react-hooks';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';


import axios, { AxiosResponse } from 'axios';
import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';
import { ActionButton, IIconProps } from 'office-ui-fabric-react';
import { Link } from 'office-ui-fabric-react/lib/Link';

import { setCurrentGame, clearCurrentGame, gameState, gameDispatchProps } from '../../Reducers/modules/currentGameReducer';
import { connect } from 'react-redux';

import gameModel from '../../models/gameModel';
import userModel from '../../models/userModel';
import resetGameModel from '../../models/resetGameModel';
import requestCardsModel from '../../models/requestCardsModel';
import gamePieceModel from '../../models/gamePieceModel';

import { RootState } from '../../Reducers/index';

import UserHistory from './UserHistory'
import GameSettings from './GameSettings'


export interface PlayGamePageProps {
}

function mapStateToProps(state: RootState): any {
    return state.game;
}

function mapDispatchToProps(dispatch: any): any {
    return {
        setCurrentGame: (newData: gameModel) => dispatch(setCurrentGame(newData)),
        clearCurrentGame: () => dispatch(clearCurrentGame())
    }
}

export type PlayGamePagePropsWithRedux = gameState & gameDispatchProps & PlayGamePageProps;



const PlayGamePage: React.FC<PlayGamePagePropsWithRedux> = (props: PlayGamePagePropsWithRedux) => {
    const [gameData, setGameData] = useState<gameModel>();
    const [gameLink, setGameLink] = useState<string>();

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [error, setError] = useState({ visible: false, message: '' });

    const [showPieces, setShowPieces] = useState(false);
    const [pieces, setPieces] = useState<gamePieceModel[]>();

    const [historyPanelOpen, { setTrue: openHistoryPanel, setFalse: dismissHistoryPanel }] = useBoolean(false);
    const [settingsPanelOpen, { setTrue: openSettingsPanel, setFalse: dismissSettingsPanel }] = useBoolean(false);
    const [resetDialogVisible, { setTrue: showResetDeialog, setFalse: dismissResetDialog }] = useBoolean(false);

    useEffect(() => {
        setGameData(props.currentgame);
        if (props !== undefined && props.currentgame !== undefined) {
            var linkName = encodeURIComponent(props.currentgame.name);
            var linkCode = encodeURIComponent(props.currentgame.gameCode);
            setGameLink("/game/join?code=" + linkCode + "&gameName='" + linkName + "'");
        }
    });

    const stackTokens: IStackTokens = {
        childrenGap: 20
    };

    const smallStackTokens: IStackTokens = {
        childrenGap: 10
    };

    const refreshIcon: IIconProps = { iconName: 'Refresh' };

    function resetGame() {
        setLoading(true);
        setLoadingMessage("Resetting game...");

        var model: resetGameModel = {
            GameCode: gameData ? gameData.gameCode : "",
            UserId: gameData && gameData.currentUser ? gameData.currentUser.id : ""
        }

        axios.put('_api/Game/ResetGame', model)
            .then(() => {
                resetRequest();
            })
            .catch((err) => {
                setError({ visible: true, message: "Error resetting game: " + err.message })
            })
    }


    function resetRequest() {
        setLoading(false);
        setLoadingMessage("");
        setShowPieces(false);
        setPieces([]);
        dismissResetDialog();
    }

    function requestCards(number: number) {
        setLoading(true);
        setLoadingMessage("Requesting pieces...");

        var model: requestCardsModel = {
            GameCode: gameData ? gameData.gameCode : "",
            UserId: gameData && gameData.currentUser ? gameData.currentUser.id : "",
            Count: number
        }

        axios.put('_api/Game/TakePieces', model)
            .then((response: AxiosResponse<gamePieceModel[]>) => {
                setLoading(false);
                setLoadingMessage("");
                setShowPieces(true);
                setPieces(response.data);
            })
            .catch((err) => {
                setError({ visible: true, message: "Error taking pieces: " + err.message })
            })
    }

    function shareLink() {
        let navigator: any;
        navigator = window.navigator;
        navigator.share({
            title: 'Game Dealer ',
            text: `Join ${gameData !== undefined ? gameData.name : "ERR"}`,
            url: gameLink,
        });
    }

    const _items: ICommandBarItemProps[] = [
        {
            key: 'info',
            text: 'Game Info',
            iconProps: { iconName: 'Settings' },
            onClick: openSettingsPanel
        },
        {
            key: 'history',
            text: 'History',
            iconProps: { iconName: 'Share' },
            onClick: openHistoryPanel
        },
        {
            key: 'share',
            text: 'Share',
            iconProps: { iconName: 'History' },
            onClick: shareLink
        }
    ];

    const _overflowItems: ICommandBarItemProps[] = [
        { key: 'reset', text: 'Reset', onClick: showResetDeialog, iconProps: { iconName: 'Refresh' } },
    ];

    const resetDialogContentProps = {
        type: DialogType.normal,
        title: 'Reset Game',
        closeButtonAriaLabel: 'Close',
        subText: 'Are you sure you want to reset the game?'
    };

    return (

        <Stack tokens={stackTokens}>
            <Text variant={'xxLarge'} block>{gameData !== undefined ? gameData.name : "ERR"}</Text>
            <CommandBar
                items={_items}
                overflowItems={_overflowItems}
                ariaLabel="Use left and right arrow keys to navigate between commands" />

            {error.visible &&
                <div>
                    <Text variant={'medium'} block>
                        {error.message}
                    </Text>
                </div>
            }
            {!error.visible && loading && !showPieces &&
                <Spinner label={loadingMessage}></Spinner>
            }
            {!error.visible && !loading && !showPieces &&
                <Stack tokens={stackTokens}>
                    <PrimaryButton text="Take 1" onClick={() => { requestCards(1) }} />
                    <PrimaryButton text="Take 2" onClick={() => { requestCards(2) }} />
                    <PrimaryButton text="Take 3" onClick={() => { requestCards(3) }} />
                    <PrimaryButton text="Take 4" onClick={() => { requestCards(4) }} />
                    <PrimaryButton text="Take 5" onClick={() => { requestCards(5) }} />
                    <PrimaryButton text="Take 6" onClick={() => { requestCards(6) }} />
                    <PrimaryButton text="Take 7" onClick={() => { requestCards(7) }} />
                    <PrimaryButton text="Take 8" onClick={() => { requestCards(8) }} />
                    <PrimaryButton text="Take 9" onClick={() => { requestCards(9) }} />
                    <PrimaryButton text="Take 10" onClick={() => { requestCards(10) }} />
                    <PrimaryButton text="Take 11" onClick={() => { requestCards(11) }} />
                    <PrimaryButton text="Take 12" onClick={() => { requestCards(12) }} />
                    <PrimaryButton text="Take 13" onClick={() => { requestCards(13) }} />
                    <PrimaryButton text="Take 14" onClick={() => { requestCards(14) }} />
                    <PrimaryButton text="Take 15" onClick={() => { requestCards(15) }} />
                    <PrimaryButton text="Take 16" onClick={() => { requestCards(16) }} />
                    <PrimaryButton text="Take 17" onClick={() => { requestCards(17) }} />
                    <PrimaryButton text="Take 18" onClick={() => { requestCards(18) }} />
                    <PrimaryButton text="Take 19" onClick={() => { requestCards(19) }} />
                    <PrimaryButton text="Take 20" onClick={() => { requestCards(20) }} />
                </Stack>
            }
            {!error.visible && showPieces &&
                <Stack tokens={stackTokens}>
                    <ActionButton iconProps={{ iconName: 'NavigateBack' }} onClick={resetRequest}>
                        Go back
                        </ActionButton>

                    <Stack horizontal wrap tokens={stackTokens}>
                        {pieces !== undefined && pieces.map(function (piece, index) {
                            return <Stack tokens={smallStackTokens}><Image src={piece.imagePath} alt={piece.name} width={90} /><Text variant={'medium'} block >{piece.name}</Text></Stack>;
                        })}
                    </Stack>
                </Stack>
            }

            <Panel
                isOpen={historyPanelOpen}
                onDismiss={dismissHistoryPanel}
                headerText="Game History"
                closeButtonAriaLabel="Close"
                isFooterAtBottom={true}>
                <div>
                    <UserHistory gameCode={gameData !== undefined ? gameData.gameCode : 'undefined'} userId={gameData && gameData.currentUser ? gameData.currentUser.id : ''} />
                </div>
            </Panel>

            <Panel
                isOpen={settingsPanelOpen}
                onDismiss={dismissSettingsPanel}
                headerText="Game Info"
                closeButtonAriaLabel="Close"
                isFooterAtBottom={true}>
                <div>
                    <GameSettings gameCode={gameData !== undefined ? gameData.gameCode : 'undefined'} userId={gameData && gameData.currentUser ? gameData.currentUser.id : ''} />
                </div>
            </Panel>

            <Dialog
                hidden={!resetDialogVisible}
                onDismiss={dismissResetDialog}
                dialogContentProps={resetDialogContentProps}
            >
                {error.visible &&
                    <Text variant={'medium'} block>{error.message}</Text>
                }
                {!error && loading &&
                    <div>
                        <Spinner label="Resetting Game. Please wait..." />
                    </div>
                }
                <DialogFooter>
                    <PrimaryButton onClick={resetGame} text="Reset" disabled={loading || error.visible} />
                    <DefaultButton onClick={resetRequest} text="Cancel" disabled={loading} />
                </DialogFooter>
            </Dialog>

        </Stack>
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlayGamePage);