import React, { useState, useEffect } from 'react';
import { PrimaryButton, IDropdownOption } from '@fluentui/react';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { Text } from 'office-ui-fabric-react/lib/Text';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { CreateGameForm } from './CreateGameForm';
import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';
import createGameModel from "../../models/CreateGameModel"
import gameModel from "../../models/gameModel"
import gameTypeModel from "../../models/gameTypeModel"

import { RootState } from '../../Reducers/index';
import { setCurrentGame, clearCurrentGame, gameState, gameDispatchProps } from '../../Reducers/modules/currentGameReducer';
import { connect } from 'react-redux';

export interface CreateGamePageProps {
    onGameCreated(): void
}

function mapStateToProps(state: RootState): any {
    return state.game;
}

function mapDispatchToProps(dispatch: any): any {
    return {
        setCurrentGame: (newData: gameModel) => dispatch(setCurrentGame(newData)),
        clearCurrentGame: () => dispatch(clearCurrentGame())
    }
}

export type CreateGamePagePropsWithRedux = gameState & gameDispatchProps & CreateGamePageProps;



const CreateGamePage: React.FC<CreateGamePagePropsWithRedux> = (props: CreateGamePagePropsWithRedux) => {

    const [gameTypes, setGameTypes] = useState<IDropdownOption[] | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [error, setError] = useState({ visible: false, message: '', title: '' });

    const stackTokens: IStackTokens = {
        childrenGap: 20
    };

    useEffect(() => {
        if (gameTypes === undefined && !loading) {
            loadGameTypes();
        }
    });

    function createGame(model: createGameModel) {
        setLoading(true);
        setLoadingMessage("Creating game");

        axios.post('_api/Game/CreateGame', model)
            .then((response: AxiosResponse<gameModel>) => {
                if (props != null && props.setCurrentGame != undefined && response != null) {
                    props.setCurrentGame(response.data);
                }

                props.onGameCreated();
            })
            .catch((err: AxiosError) => {
                var errorMessage = "We're not sure what happened there. Please try again.";
                if (err.response && err.response.status) {
                    switch (err.response.status) {
                        default:
                            errorMessage = "We're not sure what happened there. Please try again."
                    }
                }

                setError({ visible: true, message: errorMessage, title: "Ooops, that didn’t quite work as expected..." })
            })
    }

    function loadGameTypes() {
        setLoading(true);
        setLoadingMessage("Loading game types");

        axios.get('_api/Game/GetTypes')
            .then((response: AxiosResponse<gameTypeModel[]>) => {
                const { data } = response;

                var dropDownOptions = data.map(x => {
                    return {
                        key: x.id,
                        text: x.name
                    }
                });

                setGameTypes(dropDownOptions);
                setLoading(false);
            })
            .catch((err) => {
                setError({
                    visible: true, message: "Error retrieving game types: " + err.message, title: "Ooops, that didn’t quite work as expected..." })
            })
    }

    function resetControl() {
        setGameTypes([]);
        setLoading(true);
        setLoadingMessage('');
        setError({ visible: false, message: '', title: '' });
        loadGameTypes();
    }

    return (
        <Stack tokens={stackTokens}>
            {error.visible &&
                <Stack tokens={stackTokens}>
                    <Text variant={'large'} block>
                        {error.title}
                    </Text>
                    <Text variant={'medium'} block>
                        {error.message}
                    </Text>
                    <PrimaryButton text="Refresh" onClick={resetControl} />
                </Stack>
            }
            {loading && !error.visible &&
                <Spinner label={loadingMessage}></Spinner>
            }
            {!loading && !error.visible &&
                <CreateGameForm onFormSubmission={createGame} gameTypes={gameTypes as IDropdownOption[]} />
            }
        </Stack>
    );
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateGamePage);
