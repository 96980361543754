import React from 'react';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link, Text } from 'office-ui-fabric-react';

export const NavMenu: React.FC = () => {
    const smallStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: "5px 10px"
    };

    const iconClass = mergeStyles({
        fontSize: 26,
        height: 30,
        width: 30,
        margin: '10 0px'
    });

    const navBarStyles = mergeStyles({
        borderBottom: '1px solid'
    });

    return (
        <Stack horizontal tokens={smallStackTokens} className={navBarStyles}>
            <Icon iconName="Home" className={iconClass} ></Icon>
            <Text variant={'xLarge'}><Link href="/">Home</Link></Text>
        </Stack>
    );
}

export default NavMenu;