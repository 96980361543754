import React from 'react';
import "./App.css";
import Home from "../src/components/Home"
import GamePage from "../src/components/GamePage"
import { NavMenu } from '../src/components/Navigation/NavMenu';
import { initializeIcons } from '@uifabric/icons';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

const App: React.FC = () => {

    initializeIcons()

    return (
        <div>
            <NavMenu />
            <Router>
                <Switch>
                    <Route path="/game" component={GamePage}></Route>
                    <Route path="/" component={Home}></Route>
                </Switch>
            </Router>
        </div>
    );
}
export default App;