import React, { useState, useEffect } from 'react';

import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { PrimaryButton } from 'office-ui-fabric-react';

import axios, { AxiosResponse } from 'axios';
import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';

import gameInfoModel from '../../models/GameInfoModel';

interface GameSettingsProps {
    gameCode: string,
    userId: string
}

export const GameSettings: React.FC<GameSettingsProps> = (props: GameSettingsProps) => {
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [error, setError] = useState({ visible: false, message: '' });
    const [gameInfo, setGameInfo] = useState<gameInfoModel>();

    const stackTokens: IStackTokens = {
        childrenGap: 20
    };  

    useEffect(() => {
        loadGameInfo();
    }, [props.gameCode, props.userId]);

    function loadGameInfo() {
        setLoading(true);
        setLoadingMessage("Requesting game information...");

        if (props.gameCode === '' || props.userId === '') {
            return;
        }

        axios.get(`_api/Game/GameInfo?userId=${props.userId}&gameCode=${props.gameCode}`)
            .then((response: AxiosResponse<gameInfoModel>) => {
                setLoading(false);
                setLoadingMessage("");
                setGameInfo(response.data);
            })
            .catch((err) => {
                setLoading(false);
                setLoadingMessage("");
                setError({ visible: true, message: "Error loading game information " + err.message })
            });
    }

    const smallStackTokens: IStackTokens = {
        childrenGap: 10
    };


    return (
        <div>
            {error.visible &&
                <div>
                    <Text variant={'medium'} block>
                        {error.message}
                    </Text>
                </div>
            }
            {!error.visible && loading &&
                <Spinner label={loadingMessage}></Spinner>
            }
            {!error.visible && !loading && gameInfo !== undefined &&
                <Stack tokens={stackTokens}>
                    <Stack tokens={smallStackTokens}>
                        <Text variant={'large'} block>Basic Info</Text>
                        <Text variant={'medium'} block>Name: {gameInfo.gameName}</Text>
                        <Text variant={'medium'} block>Code: {gameInfo.gameCode}</Text>
                        <Text variant={'medium'} block>Type: {gameInfo.gameType !== undefined ? gameInfo.gameType.name : ''}</Text>
                        <Text variant={'medium'} block>Total Pieces: {gameInfo.totalPieces}</Text>
                        <Text variant={'medium'} block>Dealt Pieces: {gameInfo.dealtPieces}</Text>
                    </Stack>

                    <Stack tokens={smallStackTokens}>
                        <Text variant={'large'} block>Users</Text>
                        {gameInfo.users !== undefined && gameInfo.users.map(function (user, index) {
                            return <Text variant={'medium'} block>{user !== undefined ? user.name : ''}</Text>;
                        })}
                    </Stack>
                </Stack>
            }
        </div>
    )
}

export default GameSettings;