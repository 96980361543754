import React, { useState, FormEvent } from 'react';

import { PrimaryButton } from '@fluentui/react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';

import joinGameModel from '../../models/joinGameModel';

interface joinGamePageProps {
    onFormSubmission(data: joinGameModel): void,
    defaultGameCode: string,
    defaultGameName: string
}

export const JoinGameForm: React.FC<joinGamePageProps> = (props: joinGamePageProps) => {
    const [gamePasscode, setGamePasscode] = useState("");
    const [gameCode, setGameCode] = useState(props.defaultGameCode);
    const [userName, setUserName] = useState("");
    const [gameName] = useState(props.defaultGameName);

    const stackTokens: IStackTokens = {
        childrenGap: 20
    };

    function joinGame() {
        var model: joinGameModel = {
            Passcode: gamePasscode,
            Code: gameCode,
            Username: userName
        }

        props.onFormSubmission(model);
    }

    function onUserNameChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined) {
        setUserName(newValue as string);
    }

    function onGameCodeChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined) {
        setGameCode(newValue as string);
    }

    function onPasscodeChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined) {
        setGamePasscode(newValue as string);
    }

    return (
        <Stack tokens={stackTokens}>
            {props.defaultGameName !== "" &&
                <Text variant={'large'} block>Join {gameName}</Text>
            }
            {props.defaultGameName === "" &&
                <TextField label="Game code" value={gameCode} onChange={onGameCodeChange} required placeholder="What game are you trying to join?" />
            }
            <TextField label="User name" value={userName} onChange={onUserNameChange} required placeholder="What name do you want to use?" />
            <TextField label="Game pass phrase" type="password" value={gamePasscode} onChange={onPasscodeChange} required />
            <PrimaryButton text="Join" onClick={joinGame} />
        </Stack>
    );
}