import React, { useState, useEffect, FormEvent } from 'react';

import { PrimaryButton } from '@fluentui/react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';

import createGameModel from '../../models/CreateGameModel'

interface CreateGameFormProps {
    gameTypes: IDropdownOption[],
    onFormSubmission(model: createGameModel): void
}

export const CreateGameForm: React.FC<CreateGameFormProps> = (props: CreateGameFormProps) => {

    const [gameTypes, setGameTypes] = useState(props.gameTypes);
    const [gameName, setGameName] = useState('');
    const [gamePasscode, setGamePasscode] = useState('');
    const [gameType, setGameType] = useState<IDropdownOption | undefined>(undefined);
    const [userName, setUserName] = useState('');

    const stackTokens: IStackTokens = {
        childrenGap: 20
    };

    function createGame() {
        var model: createGameModel = {
            Name: gameName,
            Passcode: gamePasscode,
            GameType: gameType ? gameType.key : 0,
            UserName: userName
        }

        props.onFormSubmission(model);
    }

    function onUserNameChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined) {
        setUserName(newValue as string);
    }

    function onGameNameChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined) {
        setGameName(newValue as string);
    }

    function onPasscodeChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined) {
        setGamePasscode(newValue as string);
    }

    function onGameTypeChange(event: FormEvent<HTMLDivElement>, newValue: IDropdownOption | undefined) {
        setGameType(newValue);
    }

    return (
        <Stack tokens={stackTokens}>
            <TextField label="User name" value={userName} onChange={onUserNameChange} required placeholder="What name do you want to use?" />
            <TextField label="Game name" value={gameName} onChange={onGameNameChange} required placeholder="Give your game a name" />
            <TextField label="Game pass phrase" value={gamePasscode}  type="password"  onChange={onPasscodeChange} required />
            <Dropdown
                label="Game Type"
                selectedKey={gameType ? gameType.key : undefined}
                placeholder="Select the game you want to play"
                options={gameTypes}
                onChange={onGameTypeChange}
                required></Dropdown>
            <PrimaryButton text="Create" onClick={createGame} />
        </Stack>
    );
}