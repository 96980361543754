import React from 'react';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Icon } from '@fluentui/react/lib/Icon';
import { PrimaryButton } from '@fluentui/react';

interface BasicTileProps {
    iconName: string,
    title: string,
    description: string,
    buttonLabel: string,
    onButtonClick(): void,
    width: string
}

export const BasicTile: React.FC<BasicTileProps> = (props: BasicTileProps) => {

    const stackStyles = mergeStyles({
        textAlign: "center"
    });

    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10
    };

    const iconClass = mergeStyles({
        fontSize: 50,
        height: 50,
        width: 50,
        margin: '0 25px',
    });

    return (
        <Stack tokens={verticalGapStackTokens} className={stackStyles}>
            <Stack.Item align="center">
                <Icon iconName={props.iconName} className={iconClass} ></Icon>
            </Stack.Item>
            <Stack.Item align="stretch">
                <Text variant={'xxLarge'} block>{props.title}</Text>
            </Stack.Item>
            <Stack.Item align="stretch">
                <Text variant={'medium'} block>{props.description}</Text>
            </Stack.Item>
            <Stack.Item align="stretch">
                <PrimaryButton text={props.buttonLabel} onClick={props.onButtonClick} />
            </Stack.Item>
        </Stack>
    );
}

export default BasicTile;