import React, { useState } from 'react';
import { Router, Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

import { BasicTile } from './BaseComponents/BasicTile';
import CreateGamePage from './CreateGame/CreateGamePage';
import PlayGamePage from './PlayGame/PlayGamePage';
import JoinGamePage from './JoinGame/JoinGamePage';

import { ActionButton } from 'office-ui-fabric-react';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';

const GamePage: React.FC = () => {
     let history = useHistory();

    let { path } = useRouteMatch(),
        joinPath = path + "/join",
        createPath = path + "/create",
        playPath = path + "/play";

    const tileStackTokens: IStackTokens = {
        childrenGap: 50,
        padding: "20px 10px"
    };

    const formStackTokens: IStackTokens = {
        childrenGap: 20,
        maxWidth: 500,
        padding: "20px 10px"
    };

    function GameJoinedOrCreated() {
        history.push(playPath);
    }

    return (
        <Router history={history}>
            <Switch>
                <Route path={joinPath}>
                    <Stack horizontalAlign="center" tokens={formStackTokens}>
                        <Stack.Item align="center">
                            <Text variant={'xxLarge'} block>Join Game</Text>
                        </Stack.Item>
                     
                        <Stack.Item align="stretch">
                            <JoinGamePage onGameJoined={GameJoinedOrCreated} />
                        </Stack.Item>
                    </Stack>
                </Route>
                <Route path={createPath}>
                    <Stack horizontalAlign="center" tokens={formStackTokens}>
                        <Stack.Item align="center">
                            <Text variant={'xxLarge'} block>Create Game</Text>
                        </Stack.Item>
                        <Stack.Item align="stretch">
                            <CreateGamePage onGameCreated={GameJoinedOrCreated} />
                        </Stack.Item>
                    </Stack>
                </Route>
                <Route path={playPath}>
                    <Stack horizontalAlign="center" tokens={formStackTokens}>
                        <Stack.Item align="stretch">
                            <PlayGamePage />
                        </Stack.Item>
                    </Stack>
                </Route>
                <Route exact path={path}>
                    <Stack horizontalAlign="center" tokens={tileStackTokens}>
                        <Stack.Item align="stretch">
                            <BasicTile iconName="peopleAdd" title="Join a game" description="Join a game that some one else has already created" buttonLabel="Join Game" onButtonClick={() => { history.push(joinPath); }} width="100%" />
                        </Stack.Item>
                        <Stack.Item align="stretch">
                            <BasicTile iconName="circlePlus" title="Create a game" description="Create a new game for others to join" buttonLabel="Create Game" onButtonClick={() => { history.push(createPath); }} width="100%" />
                        </Stack.Item>
                    </Stack>
                </Route>
            </Switch>
        </Router>
    );
}

export default GamePage;