import React, { useState } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useLocation } from "react-router-dom";


import { RootState } from '../../Reducers/index';
import { setCurrentGame, clearCurrentGame, gameState, gameDispatchProps } from '../../Reducers/modules/currentGameReducer';
import { connect } from 'react-redux';

import { PrimaryButton } from '@fluentui/react';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';

import { JoinGameForm } from './JoinGameForm';

import gameModel from "../../models/gameModel"
import joinGameModel from '../../models/joinGameModel';
import errorModel from '../../models/ErrorModel';

interface joinGamePageProps {
    onGameJoined(): void
}

function mapStateToProps(state: RootState): any {
    return state.game;
}

function mapDispatchToProps(dispatch: any): any {
    return {
        setCurrentGame: (newData: gameModel) => dispatch(setCurrentGame(newData))
    }
}

export type JoinGamePagePropsWithRedux = gameState & gameDispatchProps & joinGamePageProps;


const JoinGamePage: React.FC<JoinGamePagePropsWithRedux> = (props: JoinGamePagePropsWithRedux) => {

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [error, setError] = useState({ visible: false, message: '', title: 'Ooops' });
    const location = useLocation();

    var gameCode = new URLSearchParams(location.search).get('code')
    if (gameCode === null) {
        gameCode = '';
    }

    var gameName = new URLSearchParams(location.search).get('gameName')
    if (gameName === null) {
        gameName = '';
    }

    const stackTokens: IStackTokens = {
        childrenGap: 20
    };

    function joinGame(model: joinGameModel) {
        setLoading(true);
        setLoadingMessage("Joining game");

        axios.post('_api/Game/JoinGame', model)
            .then((response: AxiosResponse<gameModel>) => {
                if (props != null && props.setCurrentGame != undefined && response != null) {
                    props.setCurrentGame(response.data);
                }

                props.onGameJoined();
            })
            .catch((err: AxiosError) => {
                var errorMessage = "We're not sure what happened there. Please try again.";
                if (err.response && err.response.status) {
                    switch (err.response.status) {
                        case 404:
                            errorMessage = "We seem to have misplaced your game. Please check your game code or follow the link sent to you again."
                            break;
                        case 401:
                            errorMessage = "It looks like you’re not allowed to join this game. Check the pass code and try again "
                            break;
                        default:
                            errorMessage = "We're not sure what happened there. Please try again."
                    }
                }

                setError({ visible: true, message: errorMessage, title: "Ooops, that didn’t quite work as expected..." })
            })
    }

    function resetControl() {
        setLoading(false);
        setLoadingMessage('');
        setError({ visible: false, message: '', title: '' });
    }

    return (
        <Stack tokens={stackTokens}>
            {error.visible &&
                <Stack tokens={stackTokens}>
                    <Text variant={'large'} block>
                        {error.title}
                    </Text>
                    <Text variant={'medium'} block>
                        {error.message}
                    </Text>
                    <PrimaryButton text="Refresh" onClick={resetControl} />
                </Stack>
            }
            {loading && !error.visible &&
                <Spinner label={loadingMessage}></Spinner>
            }
            {!loading && !error.visible &&
                <JoinGameForm onFormSubmission={joinGame} defaultGameCode={gameCode} defaultGameName={gameName} />
            }
        </Stack >
    );
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JoinGamePage);
