import React from 'react';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { useHistory } from "react-router-dom";
import { PrimaryButton } from 'office-ui-fabric-react';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';

const Home: React.FC = () => {

    let history = useHistory();

    const tileStackTokens: IStackTokens = {
        childrenGap: 50,
        padding: "20px 10px"
    };

    const formStackTokens: IStackTokens = {
        childrenGap: 20,
        maxWidth: 500,
        padding: "20px 10px"
    };

    return (

        <div>
             <Stack horizontalAlign="center" tokens={formStackTokens}>
                <Stack.Item align="center">
                    <Text variant={'xxLarge'} block>Game Dealer</Text>
                </Stack.Item>
                <Stack.Item align="center">     
                    <Stack horizontalAlign="center" tokens={formStackTokens}>
                        <Stack.Item align="center">
                            <Text variant={'medium'}>
                                Welcome to Game Dealer.
                            </Text>
                        </Stack.Item>           
                        <Stack.Item align="center">
                            <Text variant={'medium'}>
                                 The Game Dealer site is designed as a lightweight utility to allow individuals to play a variety of games remotely. 
                            </Text>
                        </Stack.Item>                        
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <PrimaryButton onClick={() => { history.push('/game'); }}>Get Started</PrimaryButton>
                </Stack.Item>
            </Stack>


        </div>
    );
}

export default Home;